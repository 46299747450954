import { ROBOT_RUN_TIME_CONFIG } from '../actions/actions';

const INITIAL_STATE = {
    SoftwareInfo: {
        arosVersion: ""

    }
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ROBOT_RUN_TIME_CONFIG: {
            return {
                ...state,
                Info: action.info
            };
        }
        default:
            return state;
    }
};
